import { Drawer }    from "@mantine/core";
import dayjs         from "dayjs";
import duration      from "dayjs/plugin/duration";
import relativeTime  from "dayjs/plugin/relativeTime";
import { useRouter } from "next/router";
import useSWR        from "swr";

import { storeEvent }                                       from "_analytics/index";
import { useGetLoggedIn, useGetProStatus, useGetUserRoles } from "_common/hooks/auth";
import {
	useGetDarkMode,
	useGetDeviceTheme,
	useGetDeviceType,
	useSetIsSidebarMenuOpen,
	useSetLoginPopup
} from "_common/hooks/global";
import { getShopLink }          from "_common/utils/shop";
import { PrimaryButton }        from "_components/atoms/button";
import Icon                     from "_components/atoms/icon";
import Image                    from "_components/atoms/image";
import Render                   from "_components/atoms/render";
import Typography               from "_components/atoms/typography";
import ConfirmModal             from "_components/molecules/confirmModal";
import { getNotificationCount } from "_services/api/user";

import NavMenuItem from "./components/navMenuItem";
import useStyles   from "./mobile.styles";

const fetcher = async () => {
	const res = await getNotificationCount ();

	return res.count;
};

const component = ( {
	isSidebarMenuOpen,
	toggleSidebarMenu,
	isHomePage,
	isLivePage,
	name,
	profileImage,
	onViewProfile,
	handleClick,
	footerList,
	onSelectAppearence,
	showThemeMenuOptions,
	closeThemeMenuOptions,
	onSelectTheme,
	setInterestialPopup,
	onClickLogout,
	isLoggedIn,
	onClickLogin,
	gameZopLinks
} ) => {
	const { classes } = useStyles ( undefined, { name: "styles" } );

	dayjs.extend ( relativeTime );
	dayjs.extend ( duration );

	const deviceType                  = useGetDeviceType ();
	const userRoles                   = useGetUserRoles ();
	const { data: notificationCount } = useSWR ( "notification-count", fetcher,
		{
			fallbackData: 0
			// revalidateIfStale     : true,
			// revalidateOnFocus     : true,
			// revalidateOnReconnect : true
		} );

	const isDeviceTheme = useGetDeviceTheme ();
	const darkMode      = useGetDarkMode ();

	const redirectTo = href => {
		var a = document.createElement ( "a" );

		a.href = href;
		a.setAttribute ( "target", "_blank" );
		a.click ();
	};

	const themeText = isDeviceTheme ? "Device Theme" : darkMode ? "Dark" : "Light";
	const isPro     = useGetProStatus ();

	let content = (
		<div className = { classes.mainContainer }>

			<Render condition = { deviceType === "mobile" }>
				<ProNavMenuItem
					expiringTime = { userRoles?.[0]?.end }
					isPro        = { isPro }
				/>
			</Render>

			<NavMenuItem
				active            = { isHomePage }
				iconMap           = { { active: "homeFilled", inactive: "homeInactive" } }
				isSidebarMenuOpen = { isSidebarMenuOpen }
				name              = "Home"
				onClick           = { () => {
					handleClick ( "/" );
				} }
			/>

			<NavMenuItem
				active            = { isLivePage }
				iconMap           = { { active: "liveFilled", inactive: "liveInactive" } }
				isSidebarMenuOpen = { isSidebarMenuOpen }
				name              = "Live"
				onClick           = { () => {
					handleClick ( "/live" );
				} }
			/>

			<NavMenuItem
				active            = { false }
				iconMap           = { { active: "reelNavActiveIcon", inactive: "reelNavBarIcon" } }
				isSidebarMenuOpen = { isSidebarMenuOpen }
				name              = "Reels"
				onClick           = { () => {
					handleClick ( "/reels" );
				} }
			/>

			<NavMenuItem
				iconMap           = { { active: "shop-bag", inactive: "shop-bag" } }
				isSidebarMenuOpen = { isSidebarMenuOpen }
				name              = "Rooter Shop"
				onClick           = { () => {
					storeEvent ( "shop", "shop_sidenav", "click", 1 );
					redirectTo ( getShopLink () );
				} }
			/>

			<Render condition = { isLoggedIn }>
				<NavMenuItem
					borderBottom
					iconMap           = { { active: "wallet-money", inactive: "wallet-money" } }
					isSidebarMenuOpen = { isSidebarMenuOpen }
					name              = "Rooter Wallet"
					onClick           = { () => {
						handleClick ( "/wallet/create" );
					} }
				/>
			</Render>

			<Render condition = { isLoggedIn }>
				<NavMenuItem
					active            = { false }
					badge             = {
						isLoggedIn && notificationCount ? (
							<Typography
								className = { classes.notificationCount }
								title     = { notificationCount > 9 ? "9+" : notificationCount }
							/>
						) : null
					}
					iconMap           = { { active: "bellFilled", inactive: "bell" } }
					isSidebarMenuOpen = { isSidebarMenuOpen }
					name              = "Notifications"
					onClick           = { () => {
						handleClick ( "/notifications" );
					} }
				>

				</NavMenuItem>
			</Render>

			<NavMenuItem
				borderBottom
				active            = { false }
				iconMap           = { { active: "moonFilled", inactive: "moon" } }
				isSidebarMenuOpen = { isSidebarMenuOpen }
				name              = "Appearance"
				onClick           = { onSelectAppearence }
			>
				<Typography
					className = { classes.themeText }
					title     = { themeText }
				/>
			</NavMenuItem>

			<NavMenuItem
				active            = { false }
				iconMap           = { { active: "createFilled", inactive: "create" } }
				isSidebarMenuOpen = { isSidebarMenuOpen }
				name              = "Become a Streamer"
				onClick           = { () => {
					setInterestialPopup ( true );
					toggleSidebarMenu ( false );
				} }
			/>

			<NavMenuItem
				active            = { false }
				iconMap           = { { active: "downloadFilled", inactive: "download" } }
				isSidebarMenuOpen = { isSidebarMenuOpen }
				name              = "Download App"
				onClick           = { () => {
					storeEvent ( "Download", "Side_nav" );
					redirectTo ( "https://rooter.app.link/ZjVfQ2tA2qb" );
				} }
			/>

			<NavMenuItem
				borderBottom
				active            = { false }
				iconMap           = { { active: "gameController", inactive: "gameController" } }
				isSidebarMenuOpen = { isSidebarMenuOpen }
				name              = "Play Games"
				onClick           = { () => {
					redirectTo ( gameZopLinks?.mobile );
				} }
			/>

			<Render condition = { isLoggedIn }>
				<ConfirmModal
					buttonComponent = { (
						<div>
							<div
								className = { classes.logoutText }
							>
								Logout
							</div>
						</div>
					) }
					dialogText      = { "Are you sure you want to log out?" }
					performAction   = { onClickLogout }
				/>
			</Render>

			<div className = { classes.footerContainer }>
				{footerList.map ( item => (
					<a key  = { item.title }
						href   = { item.link }
						rel    = "noreferrer"
						target = "_blank"
					>
						<Typography
							className = { classes.footerText }
							color     = "secondary"
							title     = { item.title }
						/>
					</a>
				)
				)}

			</div>

		</div>
	);

	if ( showThemeMenuOptions ) {
		content = (
			<div>
				<div className = { classes.appearanceMenuHeader }>
					<Icon
						sameInBothTheme
						className = { classes.backIcon }
						name      = "backArrowGray"
						onClick   = { () => closeThemeMenuOptions () }
					/>

					<Typography
						className = { classes.headerText }
						color     = "secondary"
						title     = "Appearance"
					/>

				</div>

				<div className = { isDeviceTheme ? classes.themeOptionContainerActive : classes.themeOptionContainer }
					onClick       = { () => onSelectTheme ( "device" ) }
				>
					<Typography
						className = { classes.menuItemText }
						color     = "secondary"
						title     = "Use Device Theme"
					/>

					{isDeviceTheme && (
						<Icon
							sameInBothTheme
							className = { classes.checkmarkIcon }
							name      = "checkmarkGradient"
						/>
					)}
				</div>

				<div className = { darkMode && !isDeviceTheme ? classes.themeOptionContainerActive : classes.themeOptionContainer }
					onClick       = { () => onSelectTheme ( "dark" ) }
				>
					<Typography
						className = { classes.menuItemText }
						color     = "secondary"
						title     = "Dark Mode"
					/>

					{( darkMode && !isDeviceTheme ) && (
						<Icon
							sameInBothTheme
							className = { classes.checkmarkIcon }
							name      = "checkmarkGradient"
						/>
					)}
				</div>

				<div className = { !darkMode && !isDeviceTheme ? classes.themeOptionContainerActive : classes.themeOptionContainer }
					onClick       = { () => onSelectTheme ( "light" ) }
				>
					<Typography
						className = { classes.menuItemText }
						color     = "secondary"
						title     = "Light Mode"
					/>

					{( !darkMode && !isDeviceTheme ) && (
						<Icon
							sameInBothTheme
							className = { classes.checkmarkIcon }
							name      = "checkmarkGradient"
						/>
					)}
				</div>
			</div>
		);
	}

	const proileHeader = (
		<div className = { classes.userInfoContainer }>
			{isLoggedIn ? (
				<Image
					className = { classes.profileImage }
					onClick   = { onViewProfile }
					src       = { profileImage }
				/>
			) : (
				<Icon
					sameInBothTheme
					className = { classes.userProfileAvatar }
					name      = "avatar"
				/>
			)}

			{isLoggedIn ? (
				<div className = { classes.nameAndViewMoreContainer }>
					<Typography
						noWrap
						className = { classes.name }
						title     = { `${ name }` }
					/>

					<Typography
						className = { classes.viewProfileLink }
						color     = "secondary"
						onClick   = { onViewProfile }
						title     = { "View Profile" }
					/>
				</div>
			) : (
				<div className = { classes.nonLoggedInUserInfo }>
					<Typography
						noWrap
						className = { classes.name }
						title     = { "Hi Guest" }
					/>

					<PrimaryButton
						classNames = { { root: classes.loginBtn, label: classes.loginBtnText } }
						onClick    = { onClickLogin }
						size       = "small"
						title      = { "Login" }
					/>
				</div>
			)}
		</div>
	);

	return (
		<Drawer
			classNames      = { { drawer: classes.drawer, body: classes.body, inner: classes.drawerInner } }
			onClose         = { () => toggleSidebarMenu ( false ) }
			opened          = { isSidebarMenuOpen }
			overlayProps    = { { backgroundOpacity: 0.5, blur: 4 } }
			position        = "left"
			size            = "75vw"
			withCloseButton = { false }
		>

			<div className = { classes.container }>
				<div style = { { height: "100%" } }>
					{proileHeader}

					{content}
				</div>
			</div>

		</Drawer>
	);
};

function ProNavMenuItem ( { expiringTime, isPro } ) {
	const { classes, theme } = useStyles ( undefined, { name: "pro-nav-menu-item" } );
	const closeSidebarMenu   = useSetIsSidebarMenuOpen ();
	const isLoggedIn         = useGetLoggedIn ();
	const router             = useRouter ();
	const setLoginPopup      = useSetLoginPopup ();
	const isProUser          = useGetProStatus ();
	const _expiringTime      = dayjs ( expiringTime ).isBefore () ? "Pro Expired" : `Expiring ${ dayjs ( expiringTime ).fromNow ().includes ( "in" ) ? "" : "in" } ${ dayjs ( expiringTime ).fromNow () }`;

	if ( isPro ) {
		return (
			<div
				className = { classes.menuItemContainer }
				onClick   = { () => {
					if ( !isLoggedIn ) {
						closeSidebarMenu ( false );
						setLoginPopup ( true );

						return;
					}

					if ( isProUser ) {
						router.push ( "/pro?alreadyPro=true" );

						return;
					}

					router.push ( "/pro" );
				} }
			>

				<Icon
					sameInBothTheme
					name = { "proShine" }
					size = { "2.6rem" }
				/>

				<div className = { classes.textContainer }>
					<div className = { classes.textAndBadge }>

						<Typography
							className = { classes.menuName }
							gradient  = { {
								from : theme.other.paletteNew.aquaBlue,
								to   : theme.other.paletteNew.softBlue,
								deg  : 45
							} }
							size      = "sm"
							title     = { "Pro" }
							variant   = { "gradient" }
							weight    = { 500 }
						/>

						<Typography
							className = { classes.menuName }
							color     = { "primary" }
							size      = "xs"
							title     = { _expiringTime }
						/>

					</div>

				</div>

			</div>
		);
	}

	return (

		<div
			className = { classes.menuItemContainer }
			onClick   = { () => {
				if ( !isLoggedIn ) {
					closeSidebarMenu ( false );
					setLoginPopup ( true );

					return;
				}
				router.push ( "/pro" );
			} }
		>

			<Icon
				sameInBothTheme
				name = { "proShine" }
				size = { "2.6rem" }
			/>

			<div className = { classes.textContainer }>
				<div className = { classes.textAndBadge }>
					<div className = { classes.menuItemTitle }>
						<Typography
							className = { classes.menuName }
							color     = { "primary" }
							size      = "sm"
							title     = { "Upgrade to Pro" }
							weight    = { 500 }
						/>

					</div>

				</div>

			</div>

		</div>

	);
}
export default component;
