import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( ( theme, props ) => ( {
	drawer: {
		background : "inherit",
		width      : "80%"
	},

	body: {
		// borderRadius : "1.2rem 1.2rem 0 0",
		overflow : "hidden",
		height   : "100%"
	},

	mainContainer: {
		display        : "flex",
		flexDirection  : "column",
		justifyContent : "space-between",
		height         : "calc(100% - 75px)",
		overflow       : "auto"
	},

	container: {
		background     : theme.other.colors.body.primary,
		height         : "100%",
		display        : "flex",
		flexDirection  : "column",
		justifyContent : "space-between"
	},

	userInfoContainer: {
		padding      : "1.6rem",
		display      : "flex",
		alignItems   : "center",
		borderBottom : "1px solid rgba(117, 117, 117, 0.2)"
	},

	profileImage: {
		height       : "4rem",
		width        : "4rem",
		borderRadius : "100%",
		marginRight  : "1.2rem"

	},

	name: {
		fontWeight : "500",
		fontSize   : "1.6rem",
		alignSelf  : "center"
	},

	nameAndViewMoreContainer: {
		flex: 1

	},

	nonLoggedInUserInfo: {
		display        : "flex",
		justifyContent : "space-between",
		flex           : 1
	},

	viewProfileLink: {
		fontSize: "1.4rem"
	},

	footerContainer: {
		padding: "0.5rem 1.5rem"
	},
	footerText: {
		marginBottom : "1rem",
		fontSize     : "1.4rem",

		"&:hover": {
			textDecoration: "underline"
		}
	},

	themeText: {
		fontSize : "1.4rem",
		color    : theme.other.colors.text.secondary
	},

	headerText: {
		fontSize: "1.6rem"
	},

	backIcon: {
		height      : "2.3rem",
		width       : "2.3rem",
		marginRight : "1.6rem"
	},

	appearanceMenuHeader: {
		display    : "flex",
		alignItems : "center",
		padding    : "2rem 1.6rem"
	},

	menuItemText: {
		fontSize: "1.6rem"
	},

	checkmarkIcon: {
		height : "2.3rem",
		width  : "2.3rem"
	},

	themeOptionContainerActive: {
		display        : "flex",
		alignItems     : "center",
		justifyContent : "space-between",
		padding        : "2rem 1.6rem"
	},

	themeOptionContainer: {
		padding: "2rem 1.6rem"
	},
	logoutText: {
		fontSize   : "1.6rem",
		color      : "red",
		// textTransform : "uppercase",
		marginLeft : "1.6rem",
		marginTop  : "1rem"
	},

	userProfileAvatar: {
		height       : "3rem",
		width        : "3rem",
		borderRadius : "100%",
		marginRight  : "1.2rem",
		background   : "rgba(0,0,0,0.3)"
	},
	notificationCount: {
		color           : "#fff",
		borderRadius    : "2rem",
		padding         : "0 0.5rem",
		backgroundColor : "red",
		fontSize        : "1rem",
		fontWeight      : "500",
		marginLeft      : "1rem"
	},
	drawerInner: {
		zIndex: 1000
	},
	loginBtn: {
		padding: "0.8rem 2rem"
	},
	loginBtnText: {
		fontSize: "1.4rem"
	},

	menuItemContainer: {
		alignItems   : "center",
		padding      : "1.5rem",
		cursor       : "pointer",
		borderRadius : "0 1rem 1rem 0",

		[rooterMediaQuery.mobile]: {
			padding          : "1.5rem",
			display          : "flex",
			gap              : "1rem",
			marginBottom     : "unset",
			borderRadius     : "0",
			"&.borderBottom" : {
				borderBottom: "1px solid rgba(117, 117, 117, 0.2)"
			}
		},
		"&:hover": {
			backgroundColor: props?.isSidebarMenuOpen ? ( theme.other.darkMode ? theme.other.paletteNew.darkModeDarkGrey : theme.other.paletteNew.secondaryLightGrey ) : "none"
		}

	},
	menuItem: {
		height     : "2.8rem",
		width      : "2.8rem",
		minWidth   : "2.8rem",
		marginLeft : "1.4rem",
		cursor     : "pointer",

		[rooterMediaQuery.mobile]: {
			height   : "2.4rem",
			width    : "2.4rem",
			minWidth : "2.4rem"
		},

		[rooterMediaQuery.tablet]: {
			height     : "2.8rem",
			width      : "2.8rem",
			minWidth   : "2.8rem",
			marginLeft : "1rem"
		}

	},
	menuName: {
	},

	menuItemTitle: {
		display    : "flex",
		alignItems : "center",
		gap        : "0.4rem"
	},

	offerBadge: {
		border       : "1px solid white",
		borderRadius : "2rem",
		padding      : "0 0.8rem",
		background   : theme.other.paletteNew.tertiaryPink
	},

	textContainer: {
		display        : "flex",
		alignItems     : "center",
		gap            : "0.8rem",
		justifyContent : "space-between",
		flex           : "1"
	},
	textAndBadge: {
		display       : "flex",
		flexDirection : "column",
		alignItems    : "flex-start"
	}
} ), { name: "mobileSidebarMenu" } );

